import { animated, useSprings } from '@react-spring/web'
import { easeInOutQuart } from '/machinery/motion'
import { useKeenSlider } from 'keen-slider/react'
import { useElementSize } from '@kaliber/use-element-size'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useMediaQuery } from '@kaliber/use-media-query'
import { viewportMd } from '/cssGlobal/media.css'
import { Icon } from '/features/buildingBlocks/Icon'
import { ContainerMd } from '/features/buildingBlocks/Container'

import cardLine from '/images/lines/line-team-testimonial.raw.svg'
import leftArrow from '/images/arrows/left-arrow.raw.svg'
import rightArrow from '/images/arrows/right-arrow.raw.svg'

import styles from './TeamTestimonial.css'

export function TeamTestimonial({ testimonials }) {
  return (
    <TeamTestimonialBase
      className={styles.component}
      {...{ testimonials }}
    />
  )
}

export function TeamTestimonialDark({ testimonials }) {
  return (
    <TeamTestimonialBase
      className={styles.componentDark}
      {...{ testimonials }}
      dark
    />
  )
}

function TeamTestimonialBase({ testimonials, className, dark = undefined }) {
  const isViewportMd = useMediaQuery(viewportMd)
  const { ref: sizeRef, size: { width } } = useElementSize()
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const cardsAmount = testimonials ? testimonials.length - 1 : 0
  const slides = {
    perView: isViewportMd ? 'auto' : 1,
    spacing: isViewportMd ? 0 : 25
  }
  const [springs, springsApi] = useSprings(testimonials.length, () => ({
    opacity: 0,
    ease: easeInOutQuart
  }))

  const [carouselRef, instanceRef] = useKeenSlider({
    slides,
    selector: x => x.childNodes,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const { rel, slides } = s.track.details
      springsApi.start(i => ({
        opacity: i === rel + 1
          ? 1
          : slides[i].portion
      }))
    },
    dragStarted(s) {
      pushToDataLayer({
        event: 'interaction_start',
        metadata: {
          interaction: {
            title: 'team-testimonial',
            type: 'slided',
            index: s.track.details.rel
          }
        }
      })
    },
    dragEnded(s) {
      pushToDataLayer({
        event: 'interaction_stop',
        metadata: {
          interaction: {
            title: 'team-testimonial',
            type: 'slided',
            index: s.track.details.rel
          }
        }
      })
    }
  })

  React.useEffect(
    () => { instanceRef.current.update() },
    [instanceRef]
  )

  return (
    <div className={cx(styles.componentBase, className)}>
      <ContainerMd>
        <div ref={sizeRef}>
          <div className={styles.layout}>
            <ol
              className={cx(styles.carousel, !width && styles.hidden)}
              ref={carouselRef}
            >
              {testimonials.map((item, idx) => item.quote && (
                <animated.li key={item._key} className={styles.cardListItem} style={springs[idx]}>
                  <Icon icon={cardLine} layoutClassName={styles.cardLine} />
                  <Card
                    quote={item.quote}
                    author={item.author}
                    jobRole={item.role}
                    image={item.image}
                  />
                </animated.li>
              ))}
            </ol>
            <progress
              className={cx(styles.progressBar, dark && styles.isDark)}
              value={currentSlide}
              max={cardsAmount}
            />
          </div>
        </div>
      </ContainerMd>

      {instanceRef.current && testimonials.length > 2 && (
        <NavigationButtons
          layoutClassName={styles.navButtons}
          slidesCount={instanceRef.current?.track?.details?.slides?.length}
          current={instanceRef.current}
          {...{ currentSlide }}
        />
      )}
    </div>
  )
}

function NavigationButtons({ currentSlide, slidesCount, current, layoutClassName }) {
  return (
    <div className={cx(styles.componentNavigationButtons, layoutClassName)}>
      <button
        className={styles.navButton}
        disabled={currentSlide === 0}
        onClick={handlePreviousSlide}
      >
        <Icon icon={leftArrow} />
      </button>
      <div className={styles.navButtonRightWrapper}>
        <button
          className={styles.navButton}
          disabled={currentSlide === (slidesCount - 1)}
          onClick={handleNextSlide}
        >
          <Icon icon={rightArrow} />
        </button>
        <span className={styles.counter}>{currentSlide + 1} / {slidesCount}</span>
      </div>
    </div>
  )

  function handlePreviousSlide(e) {
    e.stopPropagation()
    current?.prev()
  }

  function handleNextSlide(e) {
    e.stopPropagation()
    current?.next()
  }
}

function Card({ quote, author, jobRole, image }) {
  return (
    <div className={styles.componentCard}>
      {image?.asset && (
        <ImageCover
          aspectRatio={16 / 9}
          layoutClassName={styles.cardImage}
          {...{ image }}
        />
      )}
      <div className={styles.content}>
        {quote && (
          <blockquote className={styles.quote}>
            <q>{quote}</q>
          </blockquote>
        )}
        {author && (
          <p className={styles.author}>
            {author}
          </p>
        )}
        {jobRole && (
          <p className={styles.jobRole}>
            {jobRole}
          </p>
        )}
      </div>
    </div>
  )
}
